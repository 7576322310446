@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.link-landing-page {
	#canvas-parent canvas {
		position: absolute;
		bottom: 0;
	}
	.back-image {
		z-index: 1;
		top: 0px;
		content: "";
		position: fixed;
		left: 0px;
		width: 100%;
		height: 110%;
	}
	.overlay {
		background-color: rgba(0, 0, 0, 0.5);
		transition: opacity 0.2s linear;
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 1;
		border-radius: 6px;
	}

	.btn-round {
		border-radius: 50px;
	}

	.btn-warning {
		color: #fff;
	}

	.left {
		float: left;
	}

	.right {
		float: right;
	}

	.clearfix:before,
	.clearfix:after {
		content: " ";
		display: table;
	}
	.clearfix:after {
		clear: both;
	}

	.zindex-1 {
		z-index: 1;
	}

	.zindex-2 {
		z-index: 2;
	}

	.zindex-3 {
		z-index: 3;
	}

	.zindex-4 {
		z-index: 4;
	}

	.fixed {
		position: fixed;
	}

	.relative {
		position: relative;
	}

	.absolute {
		position: absolute;
	}

	.text-left {
		text-align: left;
	}

	.text-right {
		text-align: right;
	}

	.text-center {
		text-align: center;
	}

	.text-just {
		text-align: justify;
	}

	.align-top {
		vertical-align: top;
	}

	.align-bottom {
		vertical-align: bottom;
	}

	.align-middle {
		vertical-align: middle;
	}

	.align-baseline {
		vertical-align: baseline;
	}

	.fit {
		max-width: 100%;
	}

	.half-width {
		width: 50%;
	}

	.full-width {
		width: 100%;
	}

	.full-height {
		height: 100%;
	}

	.inline {
		display: inline;
	}

	.block {
		display: block;
	}

	.inline-block {
		display: inline-block;
	}

	.hidden {
		display: none;
	}

	.white {
		color: #fff;
	}

	.gray {
		color: #656565;
	}

	.dark-gray {
		color: #656565;
	}

	.white-bg {
		background-color: #fff;
	}

	.gray-bg {
		background-color: #656565;
	}

	.dark-gray-bg {
		background-color: #656565;
	}

	.success-bg {
		background-color: #10b759;
	}

	.alert-bg {
		background-color: #f41c0d;
	}

	.bold {
		font-weight: bold;
	}

	.regular {
		font-weight: normal;
	}

	.italic {
		font-style: italic;
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.break-word {
		word-wrap: break-word;
	}

	.no-wrap {
		white-space: nowrap !important;
	}

	.no-bullets,
	.list-bare {
		list-style: none;
	}

	.bullets-inside {
		list-style: inside;
	}

	.list-bare {
		margin: 0;
		padding: 0;
	}

	.border-all {
		border: 1px solid #e9eaec;
	}

	.border-top {
		border-top: 1px solid #e9eaec;
	}

	.border-bottom {
		border-bottom: 1px solid #e9eaec;
	}

	.border-right {
		border-right: 1px solid #e9eaec;
	}

	.border-left {
		border-left: 1px solid #e9eaec;
	}

	.border-all--dark {
		border: 1px solid #e9eaec;
	}

	.border-top--dark {
		border-top: 1px solid #e9eaec;
	}

	.border-bottom--dark {
		border-bottom: 1px solid #e9eaec;
	}

	.border-right--dark {
		border-right: 1px solid #e9eaec;
	}

	.border-left--dark {
		border-left: 1px solid #e9eaec;
	}

	/*
 * Hide visually and from screen readers:
 * http://juicystudio.com/article/screen-readers-display-none.php
 */
	.hidden {
		display: none !important;
		visibility: hidden;
	}

	/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
	.visuallyhidden {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}

	/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
	.visuallyhidden.focusable:active,
	.visuallyhidden.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}

	body,
	html {
		font-family: "Poppins", sans-serif;
		color: #494949;
		font-size: 15px;
		line-height: 1.5;
		font-weight: 300;
		-webkit-font-smoothing: antialiased;
		position: relative;
	}
	@media (max-width: 991px) {
		body,
		html {
			font-size: 14px;
			height: auto;
		}
	}
	@media (max-width: 575px) {
		body,
		html {
			font-size: 13px;
			height: auto;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}

	p {
		line-height: 24px;
		font-family: "Poppins", sans-serif;
	}

	a {
		color: #be0c3f;
	}
	a:hover {
		text-decoration: none;
		color: #1881c3;
	}

	.btn {
		padding: 8px 24px;
	}

	@media (min-width: 1400px) {
		.container-xxl {
			max-width: 1320px;
		}
	}

	.bodymain {
		height: 100%;
		padding: 80px 0;
		z-index: 2;
		position: relative;
	}

	@media (max-width: 575px) {
		.bodymain {
			padding: 0;
		}
	}

	.copyright {
		div {
			background-color: #00000045;
			display: inline-block;
			margin-right: 10%;
			margin-left: 10%;
		}
	}

	.logocard {
		border: 0;
		border-radius: 10px;
	}	

	.logocard .card-header {
		text-align: center;
		color: #fff;
		position: relative;
		border-radius: 8px;
		padding: 1.75rem 1.25rem;
	}

	.logocard .card-header h2 {
		color: #fff;
		font-size: 36px;
		font-weight: 600;
	}

	.logocard .card-header img {
		object-fit: cover;
		border-radius: 40px;
		width: 80px;
		height: 80px;
	}

	.logocard .card-header p {
		margin-bottom: 15px;
		font-size: 16px;
		font-weight: 500;
	}

	.logocard .card-body {
		padding: 0;
	}

	@media (max-width: 575px) {
		.logocard .card-header {
			position: relative;
			width: 100%;
			max-width: 100%;
			top: 0px;
			margin: 0px;
			margin-top: 50px;
		}

		.logocard .card-body {
			padding-bottom: 25px;
		}
	}

	@media (max-width: 575px) {
		.logocard .card-header h2 {
			font-size: 28px;
		}
	}

	@media (max-width: 575px) {
		.logocard .card-header p {
			font-size: 14px;
		}
		.logocard .card-header .choose-music-text {
			font-size: 13px;
		}
		.logocard .card-body {
			position: relative;
			margin-top: 0;
		}
		.logocard {
			display: block;
		}
	}

	@media (min-width: 1400px) {
		.bodymain .container {
			max-width: 1320px;
		}
	}
}

.layoutOnefeaturedLink{
	padding-top: 2%;
	padding-bottom: 2%;
	padding-right: 20%;
	padding-left: 20%;
}

.featuredLinkBtn {
	width: 100%;
	padding: 15px !important;
	font-size: 15px !important;
	white-space: normal !important;
	word-wrap: break-word !important;
}

.featuredLinkBtn:hover {
	color: var(--hover-color) !important;
	background-color: var(--hover-bg-color) !important;
	border: var(--hover-border) !important; 
	opacity: var(--hover-opacity);
}

.featuredLinkBtn:focus, .featuredLinkBtn.focus {
	box-shadow: 0 0 0 0.2rem var(--focus-box-shadow); 
}

.rounded-button {
	border-radius: 50px !important;
}

.semi-rounded-button {
	border-radius: 20px !important;
}

.squared-button {
	border-radius: 0px !important;
}